import React, { useState, useEffect } from "react";
import manPhoto1 from "../../Assets/Image/Mask group (1).png";
import check from "../../Assets/Image/Group 121.png";
import girls from "../../Assets/Image/girls.png";
import user from "../../Assets/Image/user.png";
import out from "../../Assets/Image/logout.png";
import Setting from "../../Assets/svg/setting.svg";
import Notification from "../../Assets/svg/notification.svg";
import Popover from "../../Base-Component/Popover/Popover";
import Menu from "../../Base-Component/Menu/Menu";
import Dialog from "../../Base-Component/Dialog/Dialog";
import { useNavigate } from "react-router-dom";
import { IMG_URL } from "../../Services/Api";
import { listNotification } from "../../Services/ApiServices";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";
import InfiniteScroll from 'react-infinite-scroll-component';

function TopMenu(props) {

    const navigate = useNavigate()
    const [isModalOpens, setModalOpens] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [noData, setNodata] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState("")

    const [notificationData, setNotificationData] = useState([])

    const handleLogout = () => {
        localStorage.clear()
        navigate("/")
    }

    const listNotifications = (page) => {
        let obj = { page_no: page }
        listNotification(obj)
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                const total = res.data.total_page;
                if (status == 1) {
                    if (page != 1) {
                        setNotificationData([...notificationData, ...data])
                    } else {
                        setNotificationData(data)
                    }
                    setPageNo(page)
                    setTotalPage(total)
                } else {
                    console.log("error >>", res.data.Message)
                }
            })
            .catch((err) => {
                console.log("err >>", err)
            })
    }

    const viewMores = () => {
        if (pageNo != totalPage) {
            listNotifications(pageNo + 1)
            setHasMoreData(true);
        } else {
            setHasMoreData(false);
        }
    }

    return (
        <>
            <header className="sticky top-0 z-9 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
                <div className="flex flex-grow items-center justify-between lg:justify-end py-4 px-4 shadow-2 md:px-6 2xl:px-11">
                    <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
                        {/* <!-- Hamburger Toggle BTN --> */}
                        <button
                            aria-controls="sidebar"
                            onClick={(e) => {
                                e.stopPropagation();
                                props.setSidebarOpen(!props.sidebarOpen);
                            }}
                            className="z-99 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
                        >
                            <span className="relative block h-5.5 w-5.5 cursor-pointer">
                                <span className="du-block absolute right-0 h-full w-full">
                                    <span
                                        className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${!props.sidebarOpen && "!w-full delay-300"
                                            }`}
                                    ></span>
                                    <span
                                        className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${!props.sidebarOpen && "delay-400 !w-full"
                                            }`}
                                    ></span>
                                    <span
                                        className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${!props.sidebarOpen && "!w-full delay-500"
                                            }`}
                                    ></span>
                                </span>
                                <span className="absolute right-0 h-full w-full rotate-45">
                                    <span
                                        className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${!props.sidebarOpen && "!h-0 !delay-[0]"
                                            }`}
                                    ></span>
                                    <span
                                        className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${!props.sidebarOpen && "!h-0 !delay-200"
                                            }`}
                                    ></span>
                                </span>
                            </span>
                        </button>
                    </div>
                    <div className='flex items-center'>

                        <Popover className="relative">
                            <>
                                <Popover.Button className="outline-0">
                                    <div onClick={() => listNotifications(pageNo)}>
                                        <img src={Notification} className='' alt="" />
                                    </div>
                                </Popover.Button>
                                <Popover.Panel id="notifyside" className="w-[280px] sm:w-[450px] h-[500px] overflow-x-hidden p-6 mt-6">
                                    <div className="flex items-center justify-between fontNew">
                                        <div className="font-bold text-xl">Notifications</div>
                                        {/* <div className="font-semibold" style={{ color: "#7424F2" }} >Clear all</div> */}
                                    </div>
                                    <div className="mt-6">
                                        {
                                            notificationData.map((item, index) => {
                                                return (
                                                    <div key={index} className="border-b">
                                                        <div className="flex justify-between items-center text-start fontNew my-4">
                                                            <img src={item.profile_pic ? IMG_URL + item.profile_pic : check} className="w-14 h-14 rounded-full" alt="" />
                                                            <p className="m-0 ps-3 text-black">{item.notification_text || ""}</p>
                                                            <p className="text-sm w-20 text-gray-500">{moment(item.created_at).fromNow()}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="flex justify-center mt-8">
                                        <InfiniteScroll
                                            dataLength={notificationData.length || 0}
                                            next={viewMores}
                                            hasMore={hasMoreData}
                                            loader={noData == false ? (
                                                <ThreeDots
                                                    width={40}
                                                    height={10}
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}
                                                    }
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            ) : (
                                                <h4 className='text-center text-black'>No Data found</h4>
                                            )}
                                            scrollableTarget="notifyside"
                                        />
                                    </div>
                                </Popover.Panel>
                            </>
                        </Popover>

                        <Menu>
                            <Menu.Button
                                className="block intro-x"
                            >
                                <img alt="" src={Setting} className='ps-5' />
                            </Menu.Button>
                            <Menu.Items className="w-56 mt-px text-black bg-white mt-6 fontNew">
                                <Menu.Header className="font-normal">
                                    <div className="font-bold text-xl">Settings</div>
                                </Menu.Header>
                                <Menu.Item
                                    className="hover:bg-white/5 flex justify-between items-center"
                                >
                                    <div className="flex items-center" onClick={() => { navigate("/setting") }}>
                                        <img src={user} className="w-7" alt="" />
                                        <p className="ms-3 font-medium">Account</p>
                                    </div>
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.4" d="M2 1.5L7 6.5L2 11.5" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Menu.Item>

                                <Menu.Item
                                    className="hover:bg-white/5 flex justify-between items-center"
                                >
                                    <div className="flex items-center" onClick={() => { setModalOpens(true) }}>
                                        <img src={out} className="w-7" alt="" />
                                        <p className="m-0 ms-3 fontNew font-medium text-rose-600">Log out</p>
                                    </div>
                                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.4" d="M2 1.5L7 6.5L2 11.5" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    </div>
                </div>
            </header>

            <Dialog
                open={isModalOpens}
                onClose={() => setModalOpens(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew lg:px-12 px-5 pb-3'>
                            <div className='flex justify-center my-3'>
                                <img src={out} className='w-16' alt="" />
                            </div>
                            <div className='my-4'>
                                <p className='text-3xl font-bold pb-1'>Log Out?</p>
                                <p className='text-lg font-medium text-gray-500 py-2'>are you sure you want to proceed with logging out?</p>
                            </div>
                            <div className='flex justify-between mt-8 mb-2 px-4'>
                                <button onClick={handleLogout} className='bg-[#FFD6D6] text-[#E42323] w-36 text-sm rounded-full py-3 font-semibold text-base mx-3'>Yes, Log Out</button>
                                <button onClick={() => { setModalOpens(false) }} className='bg-[#7424F2] rounded-full w-36 text-sm py-3 text-white font-semibold text-base mx-3'>Cancel</button>
                            </div>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>
        </>

    );
}

export default TopMenu;
