import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IMG_URL, LIST_SERVICE_API } from '../../Services/Api';
import Dialog from '../../Base-Component/Dialog/Dialog';
import TimeInput from 'material-ui-time-picker'
import adds from "../../Assets/Image/add.png"
import home from "../../Assets/Image/home.png"
import briefcase from "../../Assets/Image/briefcase23.png"
import location from "../../Assets/Image/location.png"
import { Bars } from 'react-loader-spinner';
import coin from '../../Assets/Image/coin.png'
import { addAddressApi, addPostAdmin, editAddressApi, listSkillProfessional, searchSkillProfessional } from '../../Services/ApiServices';
import Zoom from 'react-medium-image-zoom'
import noImage from "../../Assets/Image/noImage.png";
import moment from 'moment';
import toast from 'react-hot-toast';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import leftbtn from '../../Assets/Image/left btn.png'
import rightbtn from '../../Assets/Image/right btn.png'

function AddPost() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalOpens, setModalOpens] = useState(false);
    const [isLoader, setIsLoader] = useState(false)

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();

    const [endDate, setEndDate] = useState(null);

    const [service, setService] = useState();
    const [isFlag, setIsFlag] = useState(0)
    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState("")
    const [selectAddress, setSelectAddress] = useState("")
    const [addressId, setAddressId] = useState("")
    const [professionalId, setProfessionalId] = useState("")

    const [addressText, setAddressText] = useState("")
    const [addressPin, setAddressPin] = useState("")
    const [userDescription, setUserDescription] = useState("")
    const [search, setSearch] = useState("")
    const [hourlyRate, setHourlyRate] = useState("")

    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [serviceDetails, setServiceDetails] = useState({})
    const [professionalDetails, setProfessionalDetails] = useState({})

    const [serviceData, setServiceData] = useState([]);
    const [professionalData, setProfessionalData] = useState([])
    const [selectedDates, setSelectedDates] = useState([new Date()]);

    useEffect(() => {
        handleServiceList();
    }, []);

    useEffect(() => {
        if (isFlag == 3) {
            handleProfessional();
        }
    }, [pageNo]);

    useEffect(() => {
        console.log("selected dates >>", selectedDates)
    }, [selectedDates]);

    const onChange = (dates) => {
        setSelectedDates(dates);
    };

    const handleServiceList = () => {
        axios.post(LIST_SERVICE_API, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
                const status = res.data.Status;
                const data = res.data.info;
                if (status === 1) {
                    setServiceData(data);
                } else {
                    console.log(res.data.Message);
                }
            })
            .catch((err) => {
                console.log("err >>", err);
            });
    };

    const handleChange = (newTime) => {
        setStartTime(newTime);
    }

    const handleChanges = (newTime) => {
        setEndTime(newTime);
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const handleProfessional = () => {
        setIsLoader(true)
        if (!search) {
            let param = {
                service_id: service,
                page_no: pageNo
            }
            listSkillProfessional(param)
                .then((res) => {
                    const status = res.data.Status;
                    const data = res.data.info;
                    if (status == 1) {
                        console.log("res >>", data)
                        setProfessionalData(data)
                        setTotalPage(res.data.total_page)
                    } else {
                        console.log("error >>", res.data.Message);
                    }
                    setIsLoader(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoader(false)
                })
        } else {
            let param = {
                service_id: service,
                page_no: pageNo,
                search_text: search,
            }
            searchSkillProfessional(param)
                .then((res) => {
                    const status = res.data.Status;
                    const data = res.data.info;
                    if (status == 1) {
                        setProfessionalData(data)
                    } else {
                        console.log("error >>", res.data.Message);
                    }
                    setIsLoader(false)
                })
                .catch((err) => {
                    console.log("err >>", err);
                    setIsLoader(false)
                })
        }
    }

    const handleLatLog = (value) => {
        setAddressText(value);

        if (value) {
            // Extract the place_id from the selected value
            const placeId = value.value.place_id;

            // Fetch the place details using the Places Service
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));

            service.getDetails({ placeId }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setCoordinates({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    });

                    // Extract postal code from address components
                    const addressComponents = place.address_components;
                    const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
                    if (postalCodeComponent) {
                        setAddressPin(postalCodeComponent.long_name);
                    } else {
                        setAddressPin('Postal code not found');
                    }
                } else {
                    console.error('Error fetching place details:', status);
                }
            });
        }
    };

    const handleNext1 = () => {
        if (!service) {
            toast.error("Please Select Service");
        } else if (!startTime || !endTime) {
            toast.error("Please Select Start time & End time");
        } else {
            const start = moment(startTime);
            const end = moment(endTime);
            const duration = moment.duration(end.diff(start));
            const hours = duration.asHours();
            if (hours < 2) {
                toast.error("Start time and End time should have a minimum 2-hour difference");
            } else {
                setIsFlag(1);
            }
        }
    };

    const handleNext2 = () => {
        if (!selectAddress || !addressPin) {
            toast.error("Please enter full address");
        } else {
            if (addressText != "") {
                if (!addressId) {
                    let obj = {
                        address_type: selectAddress,
                        apartment: addressText.label,
                        zip_code: addressPin,
                        latitude: coordinates.lat,
                        longitude: coordinates.lng
                    }
                    addAddressApi(obj)
                        .then((res) => {
                            const status = res.data.Status;
                            const id = res.data.address_id;
                            if (status == 1) {
                                console.log("res >>", res.data)
                                setIsFlag(2)
                                setAddressId(id)
                            } else {
                                toast.error(res.data.Message);
                            }
                        })
                        .catch((err) => {
                            console.log("err >>", err)
                        })
                } else {
                    let obj = {
                        address_type: selectAddress,
                        apartment: addressText.label,
                        zip_code: addressPin,
                        latitude: coordinates.lat,
                        longitude: coordinates.lng,
                        address_id: addressId
                    }
                    editAddressApi(obj)
                        .then((res) => {
                            const status = res.data.Status;
                            const id = res.data.address_id;
                            if (status == 1) {
                                console.log("res >>", res.data)
                                setIsFlag(2)
                            } else {
                                toast.error(res.data.Message);
                            }
                        })
                        .catch((err) => {
                            console.log("err >>", err)
                        })
                }
            } else {
                toast.error("Please enter address");
            }
        }
    };

    const handleNext3 = () => {
        const description = userDescription.replace(/[^<>?\/\\\-+=]/g, '');
        if (!hourlyRate || !userDescription) {
            toast.error("Please enter data");
        } else if (hourlyRate < 20) {
            toast.error("Please enter minimun $20");
        } else if (description) {
            toast.error("Special characters are not allowed");
        } else {
            setIsFlag(3)
            handleProfessional()
        }
    };

    const handleNext4 = () => {
        if (!professionalId) {
            toast.error("Please select professional");
        } else {
            setIsFlag(4)
        }
    };

    const handleSubmits = () => {

        let obj = {
            accept_by: professionalId,
            service_id: service,
            address_id: addressId,
            service_hourly_price: hourlyRate,
            description: userDescription,
            post_services_status: 1,
            datetimes: JSON.stringify([{
                "date": moment(startDate).utc().format("YYYY-MM-DD"),
                "times": [{
                    "post_start_time": moment(startDate).utc().format("YYYY-MM-DD") + " " + moment(startTime).utc().format("HH:mm:ss"),
                    "post_end_time": moment(startDate).utc().format("YYYY-MM-DD") + " " + moment(endTime).utc().format("HH:mm:ss")
                }]
            }]),
        }
        addPostAdmin(obj)
            .then((res) => {
                const status = res.data.Status;
                if (status == 1) {
                    toast.success(res.data.Message);
                    setProfessionalId()
                    setService()
                    setAddressId()
                    setHourlyRate("")
                    setUserDescription("")
                    setIsFlag(0)
                } else {
                    toast.error(res.data.Message);
                }
            })
            .catch((err) => {
                console.log("err >>", err)
            })
    }

    const renderPaginationButtonss = () => {
        const maxPagesToShow = 3;
        const buttons = [];
        const startPage = Math.max(1, pageNo - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => handlePageChanges(i)}
                    className={i === pageNo ? 'px-3 py-1 mx-1 bg-black rounded-full text-white' : 'px-3 py-1 mx-1'}
                >
                    {i}
                </button>
            );
        }
        return buttons;
    };

    const handlePageChanges = (page) => {
        setPageNo(page);
    };

    return (
        <>
            <div className="grid grid-cols-12 gap-4 fontNew">
                <div className="2xl:col-span-3 xl:col-span-5 col-span-12 m-2">
                    <div className='bg-white bg-contain rounded-lg px-6 py-3'>
                        <div className='flex items-center justify-start py-4 px-6 my-5 cursor-pointer'>
                            <p className={1 <= isFlag ? 'bg-[#7424F2] flex justify-center items-center w-7 h-7 text-white rounded-full' : 'bg-gray-200 flex justify-center items-center w-7 h-7 rounded-full'}>1</p>
                            <p className='font-medium ms-5'>Date & Service Select</p>
                        </div>
                        <div className='flex items-center justify-start py-4 px-6 my-5 cursor-pointer'>
                            <p className={2 <= isFlag ? 'bg-[#7424F2] flex justify-center items-center w-7 h-7 text-white rounded-full' : 'bg-gray-200 flex justify-center items-center w-7 h-7 rounded-full'}>2</p>
                            <p className='font-medium ms-5'>Service Address</p>
                        </div>
                        <div className='flex items-center justify-start py-4 px-6 my-5 cursor-pointer'>
                            <p className={3 <= isFlag ? 'bg-[#7424F2] flex justify-center items-center w-7 h-7 text-white rounded-full' : 'bg-gray-200 flex justify-center items-center w-7 h-7 rounded-full'}>3</p>
                            <p className='font-medium ms-5'>Other Information</p>
                        </div>
                        <div className='flex items-center justify-start py-4 px-6 my-5 cursor-pointer'>
                            <p className={4 <= isFlag ? 'bg-[#7424F2] flex justify-center items-center w-7 h-7 text-white rounded-full' : 'bg-gray-200 flex justify-center items-center w-7 h-7 rounded-full'}>4</p>
                            <p className='font-medium ms-5'>Summary</p>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-9 xl:col-span-7 col-span-12">
                    {
                        isFlag == 0 ?
                            <>
                                <div className='grid grid-cols-12 bg-white lg:m-5 m-1 rounded-lg'>
                                    <div className='2xl:col-span-6 col-span-12 flex justify-center py-5'>
                                        <div>
                                            <DatePicker
                                                selectedDates={selectedDates}
                                                selectsMultiple
                                                onChange={onChange}
                                                shouldCloseOnSelect={false}
                                                minDate={new Date()}
                                                disabledKeyboardNavigation
                                                inline
                                            />
                                        </div>
                                    </div>
                                    <div className='2xl:col-span-6 col-span-12'>
                                        {serviceData.map((items, index) => (
                                            <div key={index} onClick={() => setServiceDetails(items)} className='bg-gray-100 rounded-xl py-2 px-6 m-5'>
                                                <label className='flex items-center justify-between'>
                                                    <div>
                                                        <div className='text-sm flex justify-start'>
                                                            <input
                                                                type="radio"
                                                                name="gender"
                                                                onChange={(e) => setService(e.target.value)}
                                                                className="mr-3 gen-radio"
                                                                value={items.service_id}
                                                                checked={service == items.service_id}
                                                            />
                                                            <div className='ms-3'>
                                                                <p className='text-lg font-medium'>{items.service_short_name || "-"}</p>
                                                                <p className='text-base font-medium text-gray-400'>{items.service_name || "-"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className='flex items-center'>
                                                    {
                                                        service != items.service_id ? "" :
                                                            <div className='flex items-center text-sm font-medium text-gray-600'>
                                                                <input placeholder='Start time' value={moment(startTime).format("LT")} className='w-28 h-10 m-2 px-3 bg-[#eaeaea] rounded-lg outline-none' />
                                                                <input placeholder='End time' value={moment(endTime).format("LT")} className='w-28 h-10 m-2 px-3 bg-[#eaeaea] rounded-lg outline-none' />
                                                            </div>
                                                    }
                                                    <div>
                                                        {
                                                            service != items.service_id ? "" :
                                                                <img src={adds} onClick={() => setModalOpen(true)} className='w-6 cursor-pointer' alt="" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='flex justify-end lg:px-10 px-1 py-6'>
                                    <button onClick={handleNext1} className='bg-[#7424F2] py-2 px-7 rounded-lg text-white'>Next</button>
                                </div>
                            </>
                            :
                            isFlag == 1 ?
                                <>
                                    <div className='grid grid-cols-12 bg-white lg:m-6 m-1 px-5 lg:py-10 py-3 rounded-lg'>
                                        <div className='2xl:col-span-7 xl:col-span-12 col-span-12'>
                                            <div className='flex flex-wrap items-center justify-between'>
                                                <div className='border-2 rounded-xl m-2'>
                                                    <label className='flex items-center justify-between'>
                                                        <div className='lg:py-2 py-3 lg:px-3 px-2'>
                                                            <div className='text-sm flex justify-start'>
                                                                <input
                                                                    type="radio"
                                                                    name="gender"
                                                                    onChange={(e) => setSelectAddress(e.target.value)}
                                                                    className="mr-3 address-redio"
                                                                    value="Home"
                                                                    checked={selectAddress === "Home"}
                                                                />
                                                                <div className='ms-2 flex items-center'>
                                                                    <img src={home} className='max-w-7' alt="" />
                                                                    <p className='font-medium ms-2'>Home</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className='border-2 rounded-xl m-2'>
                                                    <label className='flex items-center justify-between'>
                                                        <div className='lg:py-2 py-3 lg:px-3 px-2'>
                                                            <div className='text-sm flex justify-start'>
                                                                <input
                                                                    type="radio"
                                                                    name="gender"
                                                                    onChange={(e) => setSelectAddress(e.target.value)}
                                                                    className="mr-3 address-redio"
                                                                    value="Facility"
                                                                    checked={selectAddress === "Facility"}
                                                                />
                                                                <div className='ms-2 flex items-center'>
                                                                    <img src={briefcase} className='max-w-7' alt="" />
                                                                    <p className='font-medium ms-2'>Facility</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className='border-2 rounded-xl m-2'>
                                                    <label className='flex items-center justify-between'>
                                                        <div className='lg:py-2 py-3 lg:px-3 px-2'>
                                                            <div className='text-sm flex justify-start'>
                                                                <input
                                                                    type="radio"
                                                                    name="gender"
                                                                    onChange={(e) => setSelectAddress(e.target.value)}
                                                                    className="mr-3 address-redio"
                                                                    value="Other"
                                                                    checked={selectAddress === "Other"}
                                                                />
                                                                <div className='ms-2 flex items-center'>
                                                                    <img src={location} className='max-w-7' alt="" />
                                                                    <p className='font-medium ms-2'>Other</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='my-9'>
                                                <GooglePlacesAutocomplete
                                                    apiKey="AIzaSyB9QWKCeZH2B3aOf6kr7ho6_BY6Bwj2S3M"
                                                    selectProps={{
                                                        value: addressText,
                                                        onChange: handleLatLog,
                                                        placeholder: 'Enter your address',
                                                    }}
                                                    className="bg-white flex w-full p-3 mt-6 rounded-lg border-2 outline-none text-base fontNew"
                                                />
                                                <input type="number" value={addressPin} onChange={(e) => setAddressPin(e.target.value)} className='bg-white flex w-full p-3 mt-6 rounded-lg border-2 outline-none text-base fontNew' placeholder='Enter zipcode' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-between lg:px-10 px-1 py-6'>
                                        <button onClick={() => setIsFlag(0)} className='bg-white py-2 px-7 rounded-lg text-black'>Back</button>
                                        <button onClick={handleNext2} className='bg-[#7424F2] py-2 px-7 rounded-lg text-white'>Next</button>
                                    </div>
                                </>
                                : isFlag == 2 ?
                                    <>
                                        <div className='bg-white lg:m-6 m-1 p-6 rounded-lg'>
                                            <div className='2xl:w-[600px] xl-[500px] w-auto'>
                                                <form onSubmit={(e) => { handleSubmit(e); setIsLoader(true) }}>
                                                    <div className='bg-gray-100 flex w-full p-3 mt-6 rounded-lg text-base fontNew'>
                                                        <img src={coin} className='w-8' alt="" />
                                                        <input type="number" min={20} value={hourlyRate} onChange={(e) => setHourlyRate(e.target.value)} className='bg-transparent w-full px-4 outline-none' placeholder='Enter hourly rate minimun $20' />
                                                    </div>
                                                    <textarea type="text" required rows="5" value={userDescription} max={100} min={0} onChange={(e) => setUserDescription(e.target.value)} className='bg-gray-100 flex w-full p-4 mt-6 rounded-lg outline-none text-lg fontNew' placeholder='Description' />
                                                </form>
                                            </div>
                                        </div>
                                        <div className='flex justify-between lg:px-10 px-1 py-6'>
                                            <button onClick={() => setIsFlag(1)} className='bg-white py-2 px-7 rounded-lg text-black'>Back</button>
                                            <button onClick={handleNext3} className='bg-[#7424F2] py-2 px-7 rounded-lg text-white'>Next</button>
                                        </div>
                                    </>
                                    : isFlag == 3 ?
                                        <div className='lg:m-6 m-1'>
                                            <div className='m-5 flex justify-start'>
                                                <h1 className='text-xl font-semibold'>Select Professional for {serviceDetails.service_name || ""}</h1>
                                                {/* <input type="search" placeholder='Search Professionals....' onChange={(e) => setSearch(e.target.value)} className='w-64 border-2 px-3 py-3 rounded-lg text-sm outline-0' /> */}
                                            </div>
                                            <div className='grid grid-cols-12 bg-white rounded-lg p-4'>
                                                {
                                                    isLoader ?
                                                        <div className='h-[70vh] col-span-12 flex items-center justify-center'>
                                                            <Bars
                                                                width={30}
                                                                height={30}
                                                                color="#000"
                                                                ariaLabel="circles-loading"
                                                                wrapperStyle={{}}
                                                                visible={true}
                                                                wrapperClass=""
                                                            />
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                professionalData.map((item, index) => {
                                                                    return (
                                                                        <div key={index} onClick={() => setProfessionalDetails(item)} className='2xl:col-span-4 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12 bg-gray-100 rounded-xl py-3 px-6 m-3'>
                                                                            <label className='flex items-center justify-between'>
                                                                                <div>
                                                                                    <div className='text-sm flex justify-start'>
                                                                                        <input
                                                                                            type="radio"
                                                                                            name="gender"
                                                                                            value={item.user_id}
                                                                                            onChange={(e) => setProfessionalId(e.target.value)}
                                                                                            className="mr-3 pro-radio"
                                                                                            checked={professionalId == item.user_id}
                                                                                        />
                                                                                        <div className='ms-3'>
                                                                                            <div className='flex items-center'>
                                                                                                <Zoom>
                                                                                                    <img src={item.profile_pic ? IMG_URL + item.profile_pic : noImage} className='w-10 h-10 object-cover rounded-full' alt="" />
                                                                                                </Zoom>
                                                                                                <p className='ml-2 text-base font-medium'>{item.first_name || "-"} {item.last_name || "-"}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                }
                                                <div className="col-span-12">
                                                    <div className='flex items-center justify-end lg:mx-10 px-3 py-4'>
                                                        <div className='flex items-center cursor-pointer'>
                                                            <button onClick={() => handlePageChanges(pageNo - 1)} disabled={pageNo === 1}>
                                                                <img src={leftbtn} className='w-9 mx-3' alt="" />
                                                            </button>
                                                            <div className="flex items-center">
                                                                {renderPaginationButtonss()}
                                                            </div>
                                                            <button onClick={() => handlePageChanges(pageNo + 1)} disabled={pageNo === totalPage}>
                                                                <img src={rightbtn} className='w-9 mx-3' alt="" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex justify-between lg:px-10 px-1 py-6'>
                                                <button onClick={() => setIsFlag(2)} className='bg-white py-2 px-7 rounded-lg text-black'>Back</button>
                                                <button onClick={handleNext4} className='bg-[#7424F2] py-2 px-7 rounded-lg text-white'>Next</button>
                                            </div>
                                        </div>
                                        :
                                        <div className='m-6'>
                                            <div className='m-5 flex justify-start'>
                                                <h1 className='text-xl font-semibold'>Summary</h1>
                                            </div>
                                            <div className='grid grid-cols-12 bg-white rounded-lg py-5 px-10'>
                                                <div className='col-span-12'>
                                                    <div className='my-5'>
                                                        <p className='text-gray-400 font-medium'>Service</p>
                                                        <p className='text-lg font-medium mt-2'>{serviceDetails.service_name || "-"}</p>
                                                    </div>
                                                    <div className='my-5'>
                                                        <p className='text-gray-400 font-medium'>Date & Time</p>
                                                        <p className='text-lg font-medium mt-2'>{moment(startDate).format('Do MMMM YYYY')}</p>
                                                    </div>
                                                    <div className='my-5'>
                                                        <p className='text-gray-400 font-medium'>Hourly Rate</p>
                                                        <p className='text-lg font-medium mt-2'>$ {hourlyRate || "0"}</p>
                                                    </div>
                                                    <div className='my-5'>
                                                        <p className='text-gray-400 font-medium'>Address</p>
                                                        <p className='text-lg font-medium mt-2'>{addressText.label || "-"}</p>
                                                    </div>
                                                    <div className='my-5'>
                                                        <p className='text-gray-400 font-medium'>Description</p>
                                                        <p className='text-lg font-medium mt-2'>{userDescription || "-"}</p>
                                                    </div>
                                                    <div className='my-5'>
                                                        <p className='text-gray-400 font-medium'>Professional Details</p>
                                                        <div className='flex items-center mt-2'>
                                                            <img className='w-20 h-20 rounded-full object-cover' src={professionalDetails.profile_pic ? IMG_URL + professionalDetails.profile_pic : noImage} alt="" />
                                                            <p className='ms-3 text-lg font-medium'>{professionalDetails.first_name || "-"} {professionalDetails.last_name || "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex justify-between lg:px-10 px-1 py-6'>
                                                <button onClick={() => setIsFlag(3)} className='bg-white py-2 px-7 rounded-lg text-black'>Back</button>
                                                <button onClick={handleSubmits} className='bg-[#7424F2] py-2 px-7 rounded-lg text-white'>Submit</button>
                                            </div>
                                        </div>
                    }
                </div>
            </div >

            <Dialog
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew px-3 pb-3'>
                            <div className='flex'>
                                <div className='p-4'>
                                    <p className='text-base font-medium'>Start Time</p>
                                    <TimeInput
                                        label="Start Time"
                                        value={startTime}
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='p-4'>
                                    <p className='text-base font-medium'>End Time</p>
                                    <TimeInput
                                        label="End Time"
                                        value={endTime}
                                        required
                                        onChange={handleChanges}
                                    />
                                </div>
                            </div>
                            <button className='py-3 px-6 mt-10 w-40 rounded-2xl text-white font-medium text-base bg-[#7424F2]' onClick={() => setModalOpen(false)}>Ok</button>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>

            {/* <Dialog
                open={isModalOpens}
                onClose={() => setModalOpens(false)}
                size="md"
            >
                <Dialog.Panel>
                    <Dialog.Description className="grid grid-cols-12 p-8">
                        <div className='col-span-12 sm:col-span-12 text-center fontNew px-3 pb-3'>
                            <div>
                                <p className='text-xl font-semibold mb-6'>Add Service Address</p>
                            </div>
                            <div className='flex items-center justify-between'>
                                <div className='border-2 rounded-xl py-2 px-4 m-2'>
                                    <label className='flex items-center justify-between'>
                                        <div>
                                            <div className='text-sm flex justify-start'>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    onChange={(e) => setService(e.target.value)}
                                                    className="mr-3 address-redio"
                                                    value="Home"
                                                />
                                                <div className='ms-3'>
                                                    <img src={home} className='w-7' alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className='border-2 rounded-xl py-2 px-4 m-2'>
                                    <label className='flex items-center justify-between'>
                                        <div>
                                            <div className='text-sm flex justify-start'>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    onChange={(e) => setService(e.target.value)}
                                                    className="mr-3 address-redio"
                                                    value="Facility"
                                                />
                                                <div className='ms-3'>
                                                    <img src={briefcase} className='w-7' alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className='border-2 rounded-xl py-2 px-4 m-2'>
                                    <label className='flex items-center justify-between'>
                                        <div>
                                            <div className='text-sm flex justify-start'>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    onChange={(e) => setService(e.target.value)}
                                                    className="mr-3 address-redio"
                                                    value="Other"
                                                />
                                                <div className='ms-3'>
                                                    <img src={location} className='w-7' alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <form onSubmit={(e) => { handleSubmit(e); setIsLoader(true) }}>
                                <input type="text" max={100} min={0} className='bg-white flex w-full p-3 mt-6 rounded-lg border-2 outline-none text-base fontNew' placeholder='Enter address' />
                                <input type="number" max={100} min={0} className='bg-white flex w-full p-3 mt-6 rounded-lg border-2 outline-none text-base fontNew' placeholder='Enter zipcode' />

                                <button type='submit' className='fontNew py-3 px-6 mt-10 w-40 rounded-2xl text-white font-medium text-base bg-[#7424F2]'>
                                    {
                                        isLoader ?
                                            <div className='flex justify-center items-center'>
                                                <ThreeCircles
                                                    width={24}
                                                    height={24}
                                                    color="#fff"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    visible={true}
                                                    wrapperClass=""
                                                />
                                            </div>
                                            :
                                            "Add"
                                    }
                                </button>
                            </form>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog> */}
        </>
    );
}

export default AddPost;
